import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaEnvelope,
  FaGithub,
  FaLinkedin,
  FaMoon,
  FaSun,
} from "react-icons/fa";

export const Home = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.100", "#282c34");
  const textColor = useColorModeValue("gray.800", "gray.100");

  return (
    <Box
      bg={bgColor}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      transition="0.3s ease-in-out"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <IconButton
          position="absolute"
          top="1rem"
          right="1rem"
          aria-label="Switch dark/light mode"
          icon={colorMode === "dark" ? <FaSun /> : <FaMoon />}
          onClick={toggleColorMode}
        />
        <VStack spacing={1} alignItems={"center"}>
          <Text
            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
            color={textColor}
            fontWeight="bold"
            bgGradient="linear(to-l, teal.400,green.600)"
            bgClip="text"
          >
            Cristian Henz Krein
          </Text>
          <Text
            fontSize={{ base: "1xl", md: "2xl", lg: "3xl" }}
            color={textColor}
          >
            Software Developer
          </Text>
          <Divider
            style={{
              height: "2px",
              background:
                "linear-gradient(to right, transparent, black, transparent)",
              margin: "20px 0",
            }}
          ></Divider>

          <HStack spacing={4} alignItems={"center"}>
            <Link
              href="https://br.linkedin.com/in/cristian-henz-krein-9a0a45190"
              isExternal
            >
              <IconButton
                as={FaLinkedin}
                aria-label="LinkedIn"
                size="lg"
                colorScheme="teal"
                variant="ghost"
                _hover={{ color: "teal.500" }}
                transition="0.3s ease-in-out"
              />
            </Link>

            <Link href="https://github.com/cristiankrein" isExternal>
              <IconButton
                as={FaGithub}
                aria-label="GitHub"
                size="lg"
                colorScheme="teal"
                variant="ghost"
                _hover={{ color: "teal.500" }}
                transition="0.3s ease-in-out"
              />
            </Link>
            <Link href="mailto:cristian.krein@outlook.com">
              <IconButton
                as={FaEnvelope}
                aria-label="Email"
                size="lg"
                colorScheme="teal"
                variant="ghost"
                _hover={{ color: "teal.500" }}
                transition="0.3s ease-in-out"
              />
            </Link>
          </HStack>
        </VStack>
      </Flex>
    </Box>
  );
};
